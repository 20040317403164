<template>
  <v-container tag="section" class="px-10 pb-10">
    <v-row>
      <v-col cols="12" sm="auto">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-top="-10"
          offset-y
          content-class="w-full md:w-96"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" x-large width="100%">
              <v-icon class="mr-2"> mdi-filter-variant </v-icon>
              Điều kiện lọc
            </v-btn>
          </template>
          <v-card class="px-8 pt-8 pb-2 flex flex-col space-y-4">
            <v-select
              v-model="searchCondition"
              :items="conditionSearchList"
              no-data-text="Không có dữ liệu"
              item-text="name"
              item-value="id"
              outlined
              hide-details="false"
              label="Tìm theo"
              color="#FBB040"
              prepend-inner-icon="mdi-shape"
              @change="onSearchCondition"
              clearable
            />

            <v-autocomplete
              v-model="searchProvince"
              :items="provinceList"
              prepend-inner-icon="mdi-map-marker"
              no-data-text="Không có dữ liệu"
              item-text="name"
              item-value="ida"
              outlined
              color="#FBB040"
              label="Tỉnh thành"
              @change="onChangeProvince"
              hide-details
              clearable
            />

            <v-autocomplete
              v-model="searchDistrict"
              :items="localDistrictList"
              prepend-inner-icon="mdi-map-marker"
              no-data-text="Không có dữ liệu"
              item-text="name"
              item-value="ida"
              outlined
              color="#FBB040"
              label="Quận huyện"
              @change="onChangeDistrict"
              hide-details
              clearable
            />

            <v-autocomplete
              v-model="searchWard"
              :items="localWardList"
              prepend-inner-icon="mdi-map-marker"
              no-data-text="Không có dữ liệu"
              item-text="name"
              item-value="ida"
              outlined
              color="#FBB040"
              label="Phường xã"
              hide-details
              clearable
            />

            <!--VSelect - Vuetify-->
            <v-select
              v-model="sortCondition"
              :items="sortConditionList"
              class="text-left"
              label="Thứ tự mặc định"
              align-self="end"
              prepend-inner-icon="mdi-sort-alphabetical-descending"
              no-data-text="Không có dữ liệu"
              color="#FBB040"
              item-text="name"
              item-value="id"
              :hide-details="true"
              outlined
              @change="onChangeSortCondition"
              clearable
            />

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="menu = false"> Đóng </v-btn>
              <v-btn color="primary" text @click="onClickDefault"> Mặc định </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        lg="12"
        sm="6"
        v-if="role == 'BocThuoc' || role == 'SacThuoc' || role == 'QuanLy'"
      >
        <v-text-field
          v-model="searchString"
          hide-details="false"
          placeholder="Nội dung cần tìm"
          outlined
          color="#FBB040"
          prepend-inner-icon="mdi-magnify"
          @change="onSearch"
          @input="onSearch"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
        sm="auto"
        v-if="role == 'Admin' || role == 'BacSy' || role == 'LeTan'"
        class="flex-grow"
      >
        <v-text-field
          v-model="searchString"
          hide-details="false"
          placeholder="Nội dung cần tìm"
          outlined
          color="#FBB040"
          prepend-inner-icon="mdi-magnify"
          @change="onSearch"
          @input="onSearch"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        v-if="role == 'Admin' || role == 'BacSy' || role == 'LeTan'"
      >
        <v-btn
          min-width="180"
          width="100%"
          block
          color="success"
          @click="onCreate"
          x-large
        >
          <v-icon right dark class="mr-2"> mdi-plus-box </v-icon>
          Tạo mới
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-8 mb-8" justify="center" align="center">
      <v-col cols="12" lg="9" md="7" sm="8" :class="classStyle">
        <span class="text-lg font-medium">Tổng số: {{ totalItems }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" lg="3" md="5" sm="4"> </v-col>
    </v-row>

    <v-skeleton-loader
      :loading="isLoading"
      transition="scale-transition"
      :boilerplate="false"
      type="table"
      class="mx-auto"
    >
      <v-data-table
        item-key="id"
        :headers="headerTable"
        :items="list"
        :server-items-length="totalItems"
        :options.sync="options"
        no-data-text="Không có dữ liệu"
        no-results-text="Không có dữ liệu phù hợp"
        :footer-props="{
          itemsPerPageText: 'Dòng mỗi trang:',
          pageText: '{0}-{1} trên {2}',
          itemsPerPageAllText: 'Tất cả',
        }"
        class="elevation-1"
        @click:row="(item) => clickRow(item.id)"
        @update:page="updatePagination"
        @update:items-per-page="updatePagination"
      >
        <template v-slot:[`item.phone`]="{ item }">
          <v-chip color="orange">
            <span class="white--text">{{ item.phone }}</span>
          </v-chip></template
        >
        <template v-slot:[`item.avatar`]="{ item }">
          <v-avatar>
            <img v-if="item.avatar" :src="item.avatar" />
            <v-img
              v-if="!item.avatar"
              src="https://res.cloudinary.com/dl2pj1j7w/image/upload/v1677054520/quanlyphongkham/baodaiduong/no-image-avatar_a9gmwv.png"
            /> </v-avatar
        ></template>
        <template #footer.prepend>
          <div class="flex-grow flex items-end justify-end mr-4">
            <span>
              <v-menu :max-height="400" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-cog-outline</v-icon>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in headers" :key="index">
                    <v-checkbox v-model="item.model" :label="item.text" />
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </div>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { EMPTY } from "~/utils/constants";
import { mapState } from "vuex";

export default {
  name: "PatientList",
  data() {
    return {
      headers: [
        { text: "ID", value: "id", sortable: false, model: true },
        { text: "Tên", value: "name", sortable: false, model: true },
        { text: "Hình ảnh", value: "avatar", sortable: false, model: true },
        { text: "Số điện thoại", value: "phone", sortable: false, model: true },
        { text: "Ngày sinh", value: "dateOfBirth", sortable: false, model: true },
        { text: "CCCD", value: "identityCard", sortable: false, model: true },
        { text: "Tỉnh thành", value: "provinceName", sortable: false, model: true },
        { text: "Quận huyện", value: "districtName", sortable: false, model: true },
        { text: "Phường xã", value: "wardName", sortable: false, model: true },
        { text: "Địa chỉ", value: "address", sortable: false, model: true },
        { text: "Tạo bởi", value: "createdBy", sortable: false, model: true },
        { text: "Cập nhật", value: "updatedBy", sortable: false, model: true },
        { text: "Tạo lúc", value: "createdAt", sortable: false, model: true },
        { text: "Cập nhật", value: "updatedAt", sortable: false, model: true },
      ],
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      list: [],
      searchString: EMPTY,
      searchCondition: 1,
      searchProvince: EMPTY,
      searchDistrict: EMPTY,
      searchWard: EMPTY,
      totalItems: 0,
      isLoading: false,
      conditionSearchList: [],
      localDistrictList: [],
      localWardList: [],
      sortCondition: 1,
      sortConditionList: [],
      menu: false,
    };
  },
  async created() {
    try {
      const headers = JSON.parse(localStorage.getItem("baodaiduong-benhnhan"));
      if (headers) {
        this.headers = headers;
      }
      // get data
      this.isLoading = true;
      this.handleQueryParams();
      await this.getConditionSearchPatient();
      await this.getData();
      await this.getSortCondition();
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    localStorage.setItem("baodaiduong-benhnhan", JSON.stringify(this.headers));
  },
  computed: {
    ...mapState(["provinceList"]),
    ...mapState({
      role: (state) => state.user.roleName,
    }),
    classStyle() {
      return window.innerWidth <= 640 ? "pb-2" : "";
    },
    headerTable() {
      return this.headers.filter((item) => item.model);
    },
  },
  watch: {
    "$route.query": {
      async handler() {
        if (!Object.keys(this.$route.query).length) {
          this.options = { page: 1, itemsPerPage: 5 };
        }
        await this.getData();
      },
      deep: true,
    },
  },
  methods: {
    clickRow(patientId) {
      this.$router.push({
        name: "PatientDetail",
        params: { patientId },
      });
    },
    onCreate() {
      this.$router.push({ name: "CreatePatient" });
    },
    async handleQueryParams() {
      this.searchString = this.$route.query?.searchString || this.searchString;
      this.searchCondition = this.$route.query?.searchCondition || this.searchCondition;
      this.searchProvince = this.$route.query?.searchProvince || this.searchProvince;
      this.searchDistrict = this.$route.query?.searchDistrict || this.searchDistrict;
      this.searchWard = this.$route.query?.searchWard || this.searchWard;
      this.sortCondition = this.$route.query?.sortCondition || this.sortCondition;
      this.options.page = Number(this.$route.query?.page) || 1;
      this.options.itemsPerPage = Number(this.$route.query?.perPage) || 5;
    },
    onSearch() {
      this.options.page = 1;
      this.updateQueryParams();
    },
    async updateQueryParams() {
      const object = {};
      if (this.options.page) {
        object.page = this.options.page;
      }
      if (this.options.itemsPerPage) {
        object.perPage = this.options.itemsPerPage;
      }
      if (this.searchString) {
        object.searchString = this.searchString;
      }
      if (this.searchCondition) {
        object.searchCondition = this.searchCondition;
      }
      if (this.searchProvince) {
        object.searchProvince = this.searchProvince;
      }
      if (this.searchDistrict) {
        object.searchDistrict = this.searchDistrict;
      }
      if (this.searchWard) {
        object.searchWard = this.searchWard;
      }
      if (this.sortCondition) {
        object.sortCondition = this.sortCondition;
      }
      try {
        await this.$router.replace({ query: { ...object } });
      } catch {}
    },
    async getData() {
      try {
        this.isLoading = true;
        const { result, totalItems } = await this.$services.patientService.getPatients({
          ...this.$route.query,
        });
        this.totalItems = totalItems;
        this.list = result;
      } catch (err) {
        this.list = [];
        this.totalItems = 0;
      } finally {
        this.isLoading = false;
      }
    },
    async getConditionSearchPatient() {
      try {
        this.conditionSearchList = await this.$services.patientService.getPatientConditionSearch();
      } catch (err) {}
    },
    async updatePagination() {
      await this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.options.page,
          perPage: this.options.itemsPerPage,
        },
      });
    },
    onSearchCondition() {
      this.updateQueryParams();
    },
    onChangeSortCondition() {
      this.updateQueryParams();
    },
    async onChangeProvince() {
      this.updateQueryParams();
      this.localDistrictList = await this.$services.commonService.getDistrictBelongProvince(
        this.searchProvince
      );
      this.searchDistrict = null;
      this.searchWard = null;
    },
    async onChangeDistrict() {
      this.updateQueryParams();
      this.localWardList = await this.$services.commonService.getWardsBelongDistrict(
        this.searchDistrict
      );
    },
    async getSortCondition() {
      try {
        const data = await this.$services.patientService.getPatientSortCondition();
        this.sortConditionList = data;
      } catch (err) {
      } finally {
      }
    },
    onClickDefault() {
      this.menu = false;
      this.searchCondition = 1;
      this.searchProvince = EMPTY;
      this.searchDistrict = EMPTY;
      this.searchWard = EMPTY;
      this.sortCondition = 1;
      this.localDistrictList = [];
      this.localWardList = [];
      this.onSearch();
    },
  },
};
</script>
